const steps=[
    {
        element: '#search',
        popover: {
            title: 'Search',
            description: 'This is the search bar',
            position: 'bottom'
        }
    },
    {
        element: '#table',
        popover: {
            title: 'Table',
            description: 'This is the table',
            position: 'bottom'
        }
    }

]
export default steps